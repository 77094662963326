<template>
  <div>
    <div>
      <div class="container">
        <div class="category-cntr">
          <div class="row">
            <div class="col-md-12">
              <skeleton-loader id="boardLoader" :count="3" :showEmptyTemplate="true" :defaultLoadingProp="true">
                <template v-slot:default="{ isLoading }">
                  <div class="generic-layout-cntr" v-if="programCategories.length">
                    <div class="board-box" v-for="(category, index) in getCategories" :key="index" @click="goto(category)">
                      <div class="course-icon">
                        <img :src="category.ProgramCategory.ProgramCategoryICon" width="103" />
                      </div>
                      <div>
                        <div class="board-box-head">{{ category.ProgramCategory.Category }}</div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="isLoading" class="text-center my-5">No cateory found.</div>
                </template>
              </skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { programCategories as programCategoriesStoreKeys } from "../store/keys";
import cryptojs from "../plugins/crypto";
export default {
  mounted() {
    window.scrollTo(0, 0);
    this.getProgramCategories();
  },
  computed: {
    ...mapGetters(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.getters),
  },
  methods: {
    ...mapActions(programCategoriesStoreKeys.namespace, programCategoriesStoreKeys.actions),
    getProgramCategories() {
      this.get_ProgramCategories({
        config: {
          loader: {
            show: true,
            id: "boardLoader",
          },
        },
      });
    },
    goto(category) {
      this.$router.push({
        name: this.getRouteName(category.ProgramCategoryFlow[0].NodeKey),
        params: {
          categoryId: cryptojs.UTF.encrypt(category.ProgramCategory.ProgramCategoryId),
        },
      });
    },
  },
};
</script>

<style></style>
