export const apiEndPoints = Object.freeze({
  course: {
    getClassCourses: "api/v6/CourseService/GetCourses",
    getClasses: "api/v6/CourseService/GetClasses",
    getClassBoards: "api/v6/CourseService/GetClassBoards",
    getClassGroups: "api/v6/CourseService/GetClassGroups",
    getExams: "api/v6/CourseService/GetExams",
    getExamsGroups: "api/v6/CourseService/GetExamGroups",
    getCourseDetail: "api/v6/CourseService/GetCourseDetail",
    getProgramCategoryCourses: "api/v6/CourseService/ProgramCategoryCourses",
    // getExamCourses: "api/v6/CourseService/GetExamCourses", Not Used
    // getSpecialCoursesCount: "api/v6/CourseService/GetSpecialCoursesCount", Not Used
  },
  cart: {
    saveCart: "api/v6/CartService/SaveCart",
  },
  register: {
    registerUser: "/api/v6/CandidateService/PreEnrollmentForm",
  },
});
