export const headerType = Object.freeze({
  namespace: "headerType",
  getters: {
    isFixed: "isFixed",
  },
  actions: {
    headerToggle: "headerToggle",
  },
});

export const alert = Object.freeze({
  namespace: "alert",
  getters: {
    model: "model",
  },
  actions: {
    new: "new",
    clear: "clear",
  },
});

export const programs = Object.freeze({
  namespace: "programs",
  getters: {
    programList: "programList",
  },
  actions: {
    get_ProgramList: "getProgramList",
  },
});

export const exam = Object.freeze({
  namespace: "exam",
  getters: {
    sosCategories: "sosCategories",
    sosCategoriesPopularExams: "sosCategoriesPopularExams",
    academicPrograms: "academicPrograms",
    getExams: "getExams",
  },
  actions: {
    // get_Exams: 'getExams',
    get_Exams_Groups: "getExamsGroups",
    get_Exams_Programs: "getExamsPrograms",
  },
});

export const classes = Object.freeze({
  namespace: "classes",
  getters: {
    classes: "classes",
    getClass: "getClass",
  },
  actions: {
    get_Classes: "getClasses",
  },
});

export const course = Object.freeze({
  namespace: "course",
  getters: {
    selectedCourseInfo: "selectedCourseInfo",
    selectedProgram: "selectedProgram",
    selectedProgramCategory: "selectedProgramCategory",
    selectedBoard: "selectedBoard",
    selectedCourseGroup: "selectedCourseGroup",
    selectedCourseSOS: "selectedCourseSOS",
    selectedSOSCategory: "selectedSOSCategory",
    selectedSOSCategoryAcademicProgram: "selectedSOSCategoryAcademicProgram",
    currentWizard: "currentWizard",
  },
  actions: {
    update_SelectedClass: "updateSelectedClass",
    update_SelectedExam: "updateSelectedExam",
    update_SelectedClassBoard: "updateSelectedClassBoard",
    update_SelectedCourseGroup: "updateSelectedCourseGroup",
    update_SelectedSOS: "updateSelectedSOS",
    update_SelectedSOSCategory: "updateSelectedSOSCategory",
    update_CurrentWizard: "updateCurrentWizard",
    reset_SelectedCourseInfo: "resetSelectedCourseInfo",
    update_SelectedSOSCategoryAcademicProgram: "updateSelectedSOSCategoryAcademicProgram",
  },
});

export const courseGroup = Object.freeze({
  namespace: "courseGroup",
  getters: {
    courseGroups: "courseGroups",
  },
  actions: {
    get_CourseGroups: "getCourseGroups",
    reset_CourseGroups: "resetCourseGroups",
  },
});

export const board = Object.freeze({
  namespace: "board",
  getters: {
    boards: "boards",
  },
  actions: {
    get_ClassBoards: "getClassBoards",
    reset_Boards: "resetBoards",
  },
});

export const specialCourses = Object.freeze({
  namespace: "specialCourses",
  getters: {
    specialCourses: "specialCourses",
    canLoadMore: "canLoadMore",
  },
  actions: {
    get_SpecialCourses: "getSpecialCourses",
  },
});

export const popularExams = Object.freeze({
  namespace: "popularExams",
  getters: {
    popularExams: "popularExams",
    canLoadMore: "canLoadMore",
  },
  actions: {
    get_PopularExams: "getPopularExams",
  },
});

export const classCourses = Object.freeze({
  namespace: "courses",
  getters: {
    courseList: "courseList",
    selectedCoursePlan: "selectedCoursePlan",
    canCoursesLoadMore: "canLoadMore",
    tagsFilters: "tagsFilters",
  },
  actions: {
    get_ClassCourses: "getClassCourses",
    update_SelectedCoursePlan: "updateSelectedCoursePlan",
    reset_ClassCourses: "resetClassCourses",
    reset_TagsFilter: "resetTagsFilter",
  },
});

export const demoCourses = Object.freeze({
  namespace: "demo",
  getters: {
    getCounter: "getCounter",
  },
  actions: {
    increase_Counter: "increaseCounter",
  },
});

export const courseDetail = Object.freeze({
  namespace: "detail",
  getters: {
    courseDetail: "courseDetail",
    selectedPlan: "selectedPlan",
  },
  actions: {
    get_CourseDetail: "getCourseDetail",
    update_SelectedPlan: "updateSelectedPlan",
    reset_CourseDetail: "resetCourseDetail",
  },
});

export const signUp = Object.freeze({
  namespace: "signUp",
  getters: {},
  actions: {
    sign_Up: "signUp",
  },
});

export const cart = Object.freeze({
  namespace: "cart",
  getters: {
    cart: "cart",
    totalAmount: "totalAmount",
    strikeThroughTotalAmount: "strikeThroughTotalAmount",
    totalRecordInCart: "totalRecordInCart",
    isCourseExist: "isCourseExist",
    GetSelectedSOSFromCart: "GetSelectedSOSFromCart",
    getCartId: "getCartId",
    isStrikeThroughPriceAvailable: "isStrikeThroughPriceAvailable",
    selectedSOS: "selectedSOS",
    isPaymentPlanExist: "isPaymentPlanExist",
    getSelectedSosPaymentPackageId: "getSelectedSosPaymentPackageId",
  },
  actions: {
    add_ToCart: "addToCart",
    register: "register",
    remove_FromCart: "removeFromCart",
    check_Out: "checkOut",
    reset_Cart: "resetCart",
    sos_PaymentPackageId: "sosPaymentPackageId",
  },
});

export const checkout = Object.freeze({
  namespace: "checkout",
  actions: {
    checkout_cart: "checkout",
  },
  getters: {
    checkout_state: "checkout",
  },
  mutations: {
    add: "add",
  },
});

export const auth = Object.freeze({
  namespace: "auth",
  mutations: {},
  getters: {
    hasToken: "hasToken",
    token: "token",
  },
  actions: {},
});

export const cancelPendingReq = Object.freeze({
  namespace: "cancelPendingReq",
  getters: {},
  actions: {
    cancel_Req: "cancelReq",
    add_CancelToken: "addCancelToken",
  },
});

export const programCategories = Object.freeze({
  namespace: "programCategories",
  getters: {
    programCategories: "programCategories",
    getCategories: "getCategories",
    filteredRecords: "filteredRecords",
  },
  actions: {
    get_ProgramCategories: "getProgramCategories",
  },
});
