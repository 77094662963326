import landingLayout from "../layout/landing/landingLayout";
import PageNotFound from "../views/shared/pageNotFound.vue";
import { clientStore as store } from "../store";
import { appConstants } from "../common/constants";
import { common } from "../mixins/common";
import cryptojs from "../plugins/crypto";
import { classes as classesStoreKeys, course as courseStoreKeys, auth as authStoreKeys, cart as cartStoreKeys, exam as examStoreKeys } from "../store/keys";
import Home from "../views/home.vue";
const routes = [
  {
    path: "/",
    redirect: "/",
    component: landingLayout,
    children: [
      //     {
      //     path: "",
      //     name: "home",
      //     //component: () => import( /* webpackChunkName: "group-home" */ '../views/home'),
      //     component: Home,
      //     meta: {
      //         isPublic: true,
      //         title: 'Home'
      //     }
      // },
      {
        path: "faq",
        name: "faq",
        component: () => import(/* webpackChunkName: "group-faq" */ "../views/faq"),
        meta: {
          isPublic: true,
          title: "FAQs",
        },
      },
      {
        path: "demo",
        name: "demo",
        component: () => import(/* webpackChunkName: "group-faq" */ "../views/demo"),
        meta: {
          isPublic: true,
          title: "demo",
        },
      },
      {
        path: "courseCategory",
        name: "courseCategory",
        component: () => import(/* webpackChunkName: "group-faq" */ "../views/course/index"),
        meta: {
          isPublic: true,
          title: "Course Category",
        },
      },
      {
        path: "admission",
        name: "admission",
        component: () => import(/* webpackChunkName: "group-admission" */ "../views/admission"),
        meta: {
          isPublic: true,
          title: "Online Admissions",
        },
      },
      {
        path: "career",
        name: "career",
        component: () => import(/* webpackChunkName: "group-admission" */ "../views/career"),
        meta: {
          isPublic: true,
          title: "Career",
        },
      },
      {
        path: "",
        name: "home",
        //component: () => import( /* webpackChunkName: "group-homeNew" */ '../views/homeNew'),
        component: Home,
        meta: {
          isPublic: true,
          title: "Home",
        },
      },
      {
        path: "wizardLayout",
        name: "wizardLayout",
        component: () => import(/* webpackChunkName: "group-wizardLayout" */ "../views/wizardLayout"),
        meta: {
          isPublic: true,
          title: "wizardLayout",
        },
      },
      // {
      //     path: "demo",
      //     name: "demo",
      //     component: () => import( /* webpackChunkName: "group-demo" */ '../views/demo'),
      //     meta: {
      //         isPublic: true,
      //         title: 'Demo',
      //     }
      // },
      {
        path: "howitworks",
        name: "howitworks",
        component: () => import(/* webpackChunkName: "group-howitworks" */ "../views/howitworks"),
        meta: {
          isPublic: true,
          title: "HOW IT WORKS",
        },
      },
      {
        path: "privacy",
        name: "privacy",
        component: () => import(/* webpackChunkName: "group-privacy" */ "../views/privacy"),
        meta: {
          isPublic: true,
          title: "Privacy Policy",
        },
      },
      {
        path: "refund",
        name: "refundPolicy",
        component: () => import(/* webpackChunkName: "group-privacy" */ "../views/refundPolicy"),
        meta: {
          isPublic: true,
          title: "Refund Policy",
        },
      },
      {
        path: "terms&conditions",
        name: "terms&conditions",
        component: () => import(/* webpackChunkName: "group-privacy" */ "../views/termsAndConditions"),
        meta: {
          isPublic: true,
          title: "Terms & Conditions",
        },
      },
      {
        path: "category",
        name: "category",
        component: () => import(/* webpackChunkName: "group-class" */ "../views/course/class/program"),
        meta: {
          isPublic: true,
          title: "category",
        },
      },
      {
        path: "free-trial",
        name: "free-trial",
        component: () => import(/* webpackChunkName: "group-class" */ "../views/course/class/freeTrialProgram"),
        meta: {
          isPublic: true,
          title: "Free Trial",
        },
      },
      {
        path: "registration",
        name: "registration",
        component: () => import(/* webpackChunkName: "group-class" */ "../views/registration"),
        beforeEnter: (to, from, next) => {
          const cart = store.getters[`${cartStoreKeys.namespace}/${cartStoreKeys.getters.cart}`];
          if (Array.isArray(cart)) {
            const cartLength = cart.length;
            if (cartLength > 0) {
              next();
            } else {
              next({ name: "landingPageNotFound" });
            }
          }
          next();
        },
        meta: {
          isPublic: true,
          title: "Registration",
        },
      },
      // {
      //   path: "/exam/:academicProgram/:courseGroupId",
      //   name: "academicProgram",
      //   component: () => import(/* webpackChunkName: "group-class" */ "../views/course/exam/program"),
      //   meta: {
      //     isPublic: true,
      //     title: "program",
      //   },
      // },
      {
        path: "activities",
        name: "activities",
        component: () => import(/* webpackChunkName: "group-class" */ "../views/course/specialCourses"),
        meta: {
          isPublic: true,
          title: "program",
        },
      },
      {
        path: "popularPrograms",
        name: "popularPrograms",
        component: () => import(/* webpackChunkName: "group-class" */ "../components/categoriesFilter"),
        meta: {
          isPublic: true,
          title: "popular exams",
        },
      },
      //   {
      //     path: "category/:name/:id",
      //     name: "categoryClass",
      //     component: () => import(/* webpackChunkName: "group-class" */ "../views/course/class"),
      //     meta: {
      //       isPublic: true,
      //       title: "class",
      //       paramBinding: [
      //         { PropName: "name", Type: "string", IsEncrypted: false },
      //         { PropName: "id", Type: "number", IsEncrypted: true },
      //       ],
      //     },
      //     beforeEnter: async (to, from, next) => {
      //       try {
      //         if (process.env.VUE_ENV === "client") {
      //           let id = common.methods.PropertyBinding(to.meta.paramBinding, to.params).id;
      //           let programId = cryptojs.UTF.decrypt(to.path.split("/").pop());
      //           let classExist = store.getters[`${classesStoreKeys.namespace}/${classesStoreKeys.getters.getClass}`](id, appConstants.enums.subscriptionType.All)[0];
      //           if (classExist) {
      //             await store.dispatch(`${courseStoreKeys.namespace}/${courseStoreKeys.actions.update_SelectedClass}`, {
      //               data: {
      //                 program: {
      //                   id: classExist.AcademicProgramId,
      //                   name: classExist.Name,
      //                 },
      //                 programCategory: {
      //                   id: appConstants.enums.programCategory.Class,
      //                   name: "",
      //                 },
      //               },
      //             });

      //             next();
      //           } else {
      //             next({
      //               name: "category",
      //             });
      //           }
      //         } else {
      //           next();
      //         }
      //       } catch (ex) {
      //         console.log(ex);
      //       }
      //     },
      //   },
      //   {
      //     path: "category/:name/:id",
      //     name: "categoryExam",
      //     component: () => import(/* webpackChunkName: "group-exam" */ "../views/course/exam"),
      //     meta: {
      //       isPublic: true,
      //       title: "exam",
      //       paramBinding: [
      //         { PropName: "name", Type: "string", IsEncrypted: false },
      //         { PropName: "id", Type: "number", IsEncrypted: true },
      //       ],
      //     },
      //     beforeEnter: async (to, from, next) => {
      //       try {
      //         if (process.env.VUE_ENV === "client") {
      //           let id = common.methods.PropertyBinding(to.meta.paramBinding, to.params).id;
      //           let programId = cryptojs.UTF.decrypt(to.path.split("/").pop());
      //           let examExist = store.getters[`${examStoreKeys.namespace}/${examStoreKeys.getters.getExams}`](id, appConstants.enums.subscriptionType.All)[0];
      //           if (examExist) {
      //             await store.dispatch(`${courseStoreKeys.namespace}/${courseStoreKeys.actions.update_SelectedExam}`, {
      //               data: {
      //                 program: {
      //                   id: examExist.CourseGroupId,
      //                   name: examExist.CourseGroupName,
      //                 },
      //                 programCategory: {
      //                   id: appConstants.enums.programCategory.Exam,
      //                   name: "",
      //                 },
      //                 sosCategory: {
      //                   id: examExist.CourseGroupId,
      //                   name: examExist.CourseGroupName,
      //                 },
      //               },
      //             });

      //             next();
      //           } else {
      //             next({
      //               name: "category",
      //             });
      //           }
      //         } else {
      //           next();
      //         }
      //       } catch (ex) {
      //         console.log(ex);
      //       }
      //     },
      //   },
      {
        path: "free-trial/:name/:id",
        name: "FreeTrialClass",
        component: () => import(/* webpackChunkName: "group-class" */ "../views/course/class"),
        meta: {
          isPublic: true,
          title: "class",
          paramBinding: [
            { PropName: "name", Type: "string", IsEncrypted: false },
            { PropName: "id", Type: "number", IsEncrypted: true },
          ],
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (process.env.VUE_ENV === "client") {
              let id = common.methods.PropertyBinding(to.meta.paramBinding, to.params).id;
              let programId = cryptojs.UTF.decrypt(to.path.split("/").pop());
              // let programId = cryptojs.UTF.decrypt(to.path.split("/").pop());
              let classExist = store.getters[`${classesStoreKeys.namespace}/${classesStoreKeys.getters.getClass}`](id, appConstants.enums.subscriptionType.Free)[0];
              if (classExist) {
                await store.dispatch(`${courseStoreKeys.namespace}/${courseStoreKeys.actions.update_SelectedClass}`, {
                  data: {
                    program: {
                      id: classExist.AcademicProgramId,
                      name: classExist.Name,
                    },
                    programCategory: {
                      id: appConstants.enums.programCategory.Class,
                      name: "",
                    },
                  },
                });

                next();
              } else {
                next({
                  name: "free-trial",
                });
              }
            } else {
              next();
            }
          } catch (ex) {
            console.log(ex);
          }
        },
      },
      {
        path: "free-trial/:name/:id",
        name: "FreeTrialExam",
        component: () => import(/* webpackChunkName: "group-exam" */ "../views/course/exam"),
        meta: {
          isPublic: true,
          title: "exam",
          paramBinding: [
            { PropName: "name", Type: "string", IsEncrypted: false },
            { PropName: "id", Type: "number", IsEncrypted: true },
          ],
        },
        beforeEnter: async (to, from, next) => {
          try {
            if (process.env.VUE_ENV === "client") {
              let id = common.methods.PropertyBinding(to.meta.paramBinding, to.params).id;
              let programId = cryptojs.UTF.decrypt(to.path.split("/").pop());
              let examExist = store.getters[`${examStoreKeys.namespace}/${examStoreKeys.getters.getExams}`](id, appConstants.enums.subscriptionType.Free)[0];
              if (examExist) {
                await store.dispatch(`${courseStoreKeys.namespace}/${courseStoreKeys.actions.update_SelectedExam}`, {
                  data: {
                    program: {
                      id: examExist.CourseGroupId,
                      name: examExist.CourseGroupName,
                    },
                    programCategory: {
                      id: appConstants.enums.programCategory.Exam,
                      name: "",
                    },
                    sosCategory: {
                      id: examExist.CourseGroupId,
                      name: examExist.CourseGroupName,
                    },
                  },
                });

                next();
              } else {
                next({
                  name: "free-trial",
                });
              }
            } else {
              next();
            }
          } catch (ex) {
            console.log(ex);
          }
        },
      },
      {
        path: "class/detail/:course/:sosId/:courseGroupId",
        name: "classDetail",
        component: () => import(/* webpackChunkName: "group-class" */ "../views/course/class/detail"),
        meta: {
          isPublic: true,
          title: "detail",
          paramBinding: [
            { PropName: "course", Type: "string", IsEncrypted: false },
            { PropName: "sosId", Type: "number", IsEncrypted: true },
            { PropName: "courseGroupId", Type: "number", IsEncrypted: true },
          ],
        },
      },
      {
        path: "exam/detail/:course/:sosId/:courseGroupId",
        name: "examDetail",
        component: () => import(/* webpackChunkName: "group-exam" */ "../views/course/exam/detail"),
        meta: {
          isPublic: true,
          title: "detail",
          paramBinding: [
            { PropName: "course", Type: "string", IsEncrypted: false },
            { PropName: "sosId", Type: "number", IsEncrypted: true },
            { PropName: "courseGroupId", Type: "number", IsEncrypted: true },
          ],
        },
      },
      {
        path: "exam/:category",
        name: "examCategory",
        component: () => import(/* webpackChunkName: "group-exam" */ "../views/course/exam"),
        meta: {
          isPublic: true,
          title: "exam",
        },
      },
      {
        path: "cart",
        name: "cart",
        component: () => import(/* webpackChunkName: "group-cart" */ "../views/course/cart"),
        meta: {
          isPublic: true,
          title: "cart",
        },
      },
      {
        path: "programDetail/:programDetail",
        name: "programDetail",
        component: () => import(/* webpackChunkName: "group-programDetail" */ "../views/course/programDetail"),
        meta: {
          isPublic: true,
          title: "programDetails",
        },
      },
      {
        path: "category/:categoryId/:boardId?/:groupId?/program",
        name: "program",
        component: () => import(/* webpackChunkName: "group-programDetail" */ "../views/course/categories/program"),
        meta: {
          isPublic: true,
          title: "programDetails",
          paramBinding: [
            { PropName: "categoryId", Type: "number", IsEncrypted: true },
            { PropName: "boardId", Type: "number", IsEncrypted: true },
            { PropName: "groupId", Type: "number", IsEncrypted: true },
          ],
        },
      },
      {
        path: "category/:categoryId/:programId?/:boardId?/group",
        name: "group",
        component: () => import(/* webpackChunkName: "group-programDetail" */ "../views/course/categories/group"),
        meta: {
          isPublic: true,
          title: "programDetails",
          paramBinding: [
            { PropName: "categoryId", Type: "number", IsEncrypted: true },
            { PropName: "programId", Type: "number", IsEncrypted: true },
            { PropName: "boardId", Type: "number", IsEncrypted: true },
          ],
        },
      },
      {
        path: "category/:categoryId/:programId?/:groupId?/board",
        name: "board",
        component: () => import(/* webpackChunkName: "group-programDetail" */ "../views/course/categories/board"),
        meta: {
          isPublic: true,
          title: "programDetails",
          paramBinding: [
            { PropName: "categoryId", Type: "number", IsEncrypted: true },
            { PropName: "programId", Type: "number", IsEncrypted: true },
            { PropName: "groupId", Type: "number", IsEncrypted: true },
          ],
        },
      },
      {
        path: "category/:categoryId/:programId?/:groupId?/:boardId?/course",
        name: "course",
        component: () => import(/* webpackChunkName: "group-programDetail" */ "../views/course/categories/course"),
        meta: {
          isPublic: true,
          title: "programDetails",
          paramBinding: [
            { PropName: "categoryId", Type: "number", IsEncrypted: true },
            { PropName: "programId", Type: "number", IsEncrypted: true },
            { PropName: "groupId", Type: "number", IsEncrypted: true },
            { PropName: "boardId", Type: "number", IsEncrypted: true },
          ],
        },
      },
      {
        path: "*",
        name: "landingPageNotFound",
        component: PageNotFound,
        meta: {
          isPublic: true,
          title: "Error",
        },
      },
    ],
  },
  // {
  //     path: "*",
  //     name: "PageNotFound",
  //     meta: {
  //         isPublic: true,
  //         title: 'Error'
  //     },
  //     component: landingLayout,
  //     children: [
  //         {
  //             path: "*",
  //             name: "landingPageNotFound",
  //             component: PageNotFound,
  //             meta: {
  //                 isPublic: true,
  //                 title: 'Error'
  //             }
  //         }
  //     ]
  // }
];

export default routes;
